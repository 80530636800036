import $ from 'jquery';
import select2 from 'select2';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';

function initSelect2() {
  select2($);
}

const initAllOptionSelect2 = function() {
  $('.all-option-select2').select2({
    theme: 'bootstrap4',
    width: 'resolve'
  }).on('select2:select', function (e) {
    var $element = $(e.params.data.element);
    if ($element.val() === 'all') {
      $(this).val(['all']);
    } else if ($.inArray('all', $(this).val()) === -1 && $(this).val().length === $(this).find('option').length - 1) {
      $(this).val(['all']);
    } else {
      var values = $(this).val().filter(function(item) {
        return item !== 'all'
      })
      $(this).val(values);
    }
    $(this).trigger('change');
  });
}

$( document ).ready(function() {
  $('.select2').select2({
    theme: 'bootstrap4',
    width: 'resolve'
  });

  initAllOptionSelect2();
});

window.initAllOptionSelect2 = initAllOptionSelect2;

export default initSelect2
