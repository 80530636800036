
import Rails from '@rails/ujs';

// Elements we want to listen to for data-confirm
const elements = [
  "a[data-confirm]",
  "button[data-confirm]",
  "input[type=submit][data-confirm]",
];

const createConfirmModal = (element) => {
  var confirm = element.dataset.confirm;
  var successMessage = element.dataset.successMessage || "Item Deleted!";
  var btnClass = element.dataset.btnClass || "bg-danger";
  element.dataset.confirmModal = "open";

  swal({
    title: confirm,
    text: 'You will not be able to recover this!',
    icon: 'warning',
    buttons: {
      cancel: true,
      confirm: {
        text: 'Yes, do it!',
        value: true,
        visible: true,
        className: btnClass,
        closeModal: true
      }
    }
  }).then((willDelete) => {
    if (willDelete) {
      // Allow the confirm to go through
      Rails.confirm = () => {
        return true;
      };

      // Click the link again
      element.click();

      swal(successMessage, {
        icon: "success",
      });
    } else {
      swal("Cancelled", "Ok, action canceled", "error");
    }
    element.dataset.confirmModal = "close";
  });
};

const handleConfirm = (event) => {
  if (event.target.dataset.confirmModal == "open") {
    return true;
  } else {
    createConfirmModal(event.target);
    return false;
  }
};

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(", "), "confirm", handleConfirm);
