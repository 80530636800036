import $ from 'jquery';

import initBootgrid from './angle/modules/tables/bootgrid';
import initDatatables from './angle/modules/tables/datatable';
import initTableCheckAll from './angle/modules/common/table-checkall.js';
import './angle/modules/forms/show_password.js';


initBootgrid();
initDatatables();
initTableCheckAll();

hideFlashMessages()

$(document).on('change', '.table-select-all', function () {
  const checkboxes = $(this).closest('table').find('tbody :checkbox').not(":disabled");
  if ($(this).is(':checked')) {
    checkboxes.prop('checked', true).change();
  } else {
    checkboxes.prop('checked', false).change();
  }
})

$(document).on('change', '.submit-on-change', function () {
  $(this).closest('form').submit();
})

$(document).on('change', 'td>input[type="checkbox"]', function () {
  if (!$(this).closest('tbody').find('td>input[type="checkbox"]').is(':checked')) {
    $(this).closest('table').find('.table-select-all').prop('checked', false);
  }
})

$(document).on('change', '[data-undisable="true"]', function () {
  if ($('[data-undisable="true"]').is(':checked')) {
    $($(this).data('undisable-target')).prop('disabled', false);
  } else {
    $($(this).data('undisable-target')).prop('disabled', true);
  }
})

function hideFlashMessages() {
  setTimeout(() => {
    $('.remove-flash-message').remove()
  }, 10000)
}

window.hideFlashMessages = hideFlashMessages;

function showFlash(status, message) {
  var alert = document.getElementById('hidden-alert')
  alert.innerHTML = message
  alert.classList.remove('hidden')
  alert.classList.add(`alert-${status}`)

  setTimeout(() => {
    alert.innerHTML = ""
    alert.classList.add('hidden')
    alert.classList.remove(`alert-${status}`)
  }, 10000)
}

window.showFlash = showFlash;

function successFlash(message) {
  showFlash('success', message)
}

window.successFlash = successFlash;

function errorFlash(message) {
  showFlash('danger', message)
}

window.errorFlash = errorFlash;
