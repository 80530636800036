import $, { error } from "jquery";

$(document).on("change", ".trigger-batch-feed-tags", function(e) {
  e.target.dataset.seriesTarget.split(' ').forEach(function (element) {
    var $seriesElement = $(element);
    initSeriesSelect($seriesElement);
  });
});

function initSeriesSelect($seriesElement) {
  var $actorElement = $($seriesElement.data('seriesActorTarget'))
  var $amountElement = $($seriesElement.data('seriesAmountTarget'))

  $seriesElement.closest('.series-wrapper').addClass('d-none')
  $seriesElement.empty()
  $actorElement.parent().find('.text-danger').remove()

  if ($amountElement.val() !== '' && parseInt($amountElement.val()) <= 10 && $actorElement.val() !== '') {
    var url = $seriesElement.data('seriesUrl').replace('ACTOR_ID', $actorElement.val()).replace('AMOUNT', $amountElement.val())
    $seriesElement.closest('.series-wrapper').removeClass('d-none')
    $.ajax({
      url: url,
      success: function(data) {
        $seriesElement.select2({
          theme: 'bootstrap4',
          width: '100%',
          multiple: false,
          data: data,
          ajax: {
            url: url,
            dataType: 'json',
            delay: 250,
            processResults: function (data) {
              return {
                results: data
              };
            },
            cache: false
          }
        });
      }
    });
  }
};

$(document).on("change", ".trigger-close-series", function(e) {
  var $seriesElement = $(e.target.dataset.seriesTarget)
  $seriesElement.closest('.series-wrapper').addClass('d-none')
  $seriesElement.empty()
});
