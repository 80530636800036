import $ from 'jquery';
import {ujsGet} from './ujs_helpers';

const toggleDisableFeedSubIdFields = () => {
  let disabled = !$('#feed_url_sub_id_param').val()?.length && $('#feed_url_id_value_usage').val() !== 'parameter'

  $('#feed_sub_id_prefix').prop('disabled', disabled);
  $('#feed_min_subids').prop('disabled', disabled);
  $('#feed_max_subids').prop('disabled', disabled);
}

$(() => {
  $('#feed_url_id_value_usage').on('change', (event) => {
    let usage = event.target.value;
    if(usage === 'parameter') {
      $('#feed_url_id_param').prop('disabled', false);
      toggleDisableFeedSubIdFields();
    }else {
      $('#feed_url_id_param').prop('disabled', true);
      toggleDisableFeedSubIdFields();
    }
  });

  $('#feed_url_sub_id_param').on('change', () => {
    toggleDisableFeedSubIdFields();
  })
  $('.feed-type').on('change', (event) => {
    const $this = $(event.target)
    const type = $this.val()
    const feedId = $this.parent().data('id')
    ujsGet('/feeds/load_extra_fields', { feed_id: feedId, feed_type: type })
    ujsGet('/feeds/load_account_dropdown', { feed_type: type, feed_id: feedId })
  })

  $('*[data-target="toggle-parent"]').on('change', (event) => {
    var childrenClass = $(event.target).data('childToggleTarget');

    if (event.target.value === '') {
      $(childrenClass).prop('disabled', true);
    } else {
      $(childrenClass).prop('disabled', false);
    }
  });

  $('.multi-restricted-countries').select2({
    theme: 'bootstrap4',
    width: 'resolve',
    placeholder: 'Select countries',
  });

  $('.restricted-countries-override-channel-select2').select2({
    theme: 'bootstrap4',
    width: 'resolve',
    ajax: {
      url: '/channel_tags',
      dataType: 'json',
      delay: 250,
      data: function (params) {
        var query = {
          q: params.term,
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data.map((channel) => {
            return {
              id: channel.tag,
              text: channel.tag,
            };
          }),
        };
      },
      cache: true
    }
  });
});

