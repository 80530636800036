import $ from 'jquery';

$(document).on('change', '.preview-record-checkbox', function () {
  if ($(this).closest('table').find('.preview-record-checkbox').is(':checked')) {
    $('.generate-form-button').removeAttr('disabled')
  } else {
    $('.generate-form-button').attr('disabled', 'disabled')
  }
})

$(document).on('change', '.preview-candidate-checkbox', function () {
  if ($(this).closest('table').find('.preview-candidate-checkbox').is(':checked')) {
    $('.import-preview-candidate-button').removeAttr('disabled')
  } else {
    $('.import-preview-candidate-button').attr('disabled', 'disabled')
  }
})

$(document).on('change', '.estimable-field', function () {
  const $this = $(this);
  const $reports_input = $("#feed_reports")
  const reports = JSON.parse($reports_input.val()).map(function(report){
    if ( report.id_for_generate == $this.data("reportId")) {
      report[$this.data("fieldName")] = $this.val();
    }
    return report;
  });
  $reports_input.val(JSON.stringify(reports));
});
