import $ from 'jquery';

import 'bootstrap/js/dist/popover';

function preInitXEditableProtectedIp() {
    if ($('.x-editable-card').length) {
        // xEditable
        // trick to load xeditable only when used on page and avoid issue with bootstrap-datepicker
        import('x-editable-bs4/dist/bootstrap4-editable/css/bootstrap-editable.css').then(() => {
            import('x-editable-bs4/dist/bootstrap4-editable/js/bootstrap-editable.min.js').then(() => {
                initXEditableProtectedIp();
            });
        });
    }
}

function editableParams(namespace, params) {
    params.authenticity_token = $('[name="csrf-token"]')[0].content;
    const gsParams = {};
    gsParams[params.name] = params.value;
    params[namespace] = gsParams;
    return params;
}

function initXEditableProtectedIp() {
    if (!$.fn.editable) return

    // Font Awesome support
    $.fn.editableform.buttons =
        '<button type="submit" class="btn btn-primary btn-sm editable-submit">' +
        '<i class="fa fa-fw fa-check"></i>' +
        '</button>' +
        '<button type="button" class="btn btn-default btn-sm editable-cancel">' +
        '<i class="fa fa-fw fa-times"></i>' +
        '</button>';

    //defaults
    $.fn.editable.defaults.ajaxOptions = { type: "PUT" };

    $('#protected_ip_limit').editable({
        mode: 'inline',
        params: (params) => { return editableParams('protected_ip_limit', params) },
    });
}


$(function() {
  preInitXEditableProtectedIp();
});
