import $ from 'jquery';
import Rails from '@rails/ujs';

import 'bootstrap/js/dist/popover';

function preInitXEditable() {
    if ($('.x-editable-card').length) {
        // xEditable
        // trick to load xeditable only when used on page and avoid issue with bootstrap-datepicker
        import('x-editable-bs4/dist/bootstrap4-editable/css/bootstrap-editable.css').then(() => {
            import('x-editable-bs4/dist/bootstrap4-editable/js/bootstrap-editable.min.js').then(() => {
                initXEditable();
            });
        });
    }
}

function editableParams(params, id = true) {
  console.log(params)
    params.authenticity_token = $('[name="csrf-token"]')[0].content;
    const gsParams = {};
    gsParams[id ? `${params.name}_id` : params.name] = params.value;
    params.global_settings = gsParams;
    return params;
}

function initXEditable() {
    if (!$.fn.editable) return

    // Font Awesome support
    $.fn.editableform.buttons =
        '<button type="submit" class="btn btn-primary btn-sm editable-submit">' +
        '<i class="fa fa-fw fa-check"></i>' +
        '</button>' +
        '<button type="button" class="btn btn-default btn-sm editable-cancel">' +
        '<i class="fa fa-fw fa-times"></i>' +
        '</button>';

    //defaults
    $.fn.editable.defaults.ajaxOptions = { type: "PUT" };

    $('#fallback_browser_feed_tag').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params) },
    });

    $('#fallback_geo_feed_tag').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params) },
    });

    $('#fallback_device_feed_tag').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params) },
    });

    $('#estimation_monetized_searches_percentage').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params, false) },
    });
    $('#estimation_clicks_percentage').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params, false) },
    });
    $('#estimation_feed_payment_percentage').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params, false) },
    });
    $('#estimation_drop').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params, false) },
    });

    $('#publisher_report_email').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params, false) },
    });
    $('#publisher_report_subject').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params, false) },
    });
    $('#publisher_report_body').editable({
        mode: 'inline',
        inputclass: 'publisher_report_body_textarea',
        params: (params) => { return editableParams(params, false) },
    });

    $('#partner_revshare').editable({
        mode: 'inline',
        validate: function(value) {
            const floatValue = parseFloat(value);
            if (isNaN(floatValue)) return 'This field should be a number';
            if (floatValue < 0 || floatValue > 1.0) return 'Partner Revshare should be between 0 and 1'
        },
        params: (params) => { return editableParams(params, false) },
    });
    $('#publisher_report_token').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params, false) },
    });
    $('#global_setting_publisher_report_enabled').on("change", (event) => {
        const action = event.target.checked == true ? "enable" : "disable";
        swal({
            title: `Are you sure you want to ${action} api token?`,
            icon: 'warning',
            buttons: {
              cancel: true,
              confirm: {
                text: 'Yes, do it!',
                value: true,
                visible: true,
                className: "bg-success",
                closeModal: true
              }
            }
          }).then((willDelete) => {
            if (willDelete) {
              Rails.fire(document.getElementById("enable-publisher-token-form"), 'submit');
              swal("Process", {
                icon: "success",
              });
            } else {
              swal("Cancelled", "Ok, action canceled", "error");
              event.target.checked = !event.target.checked;
            }
          });
    });
    $('#account_segment_for_finance_profit_report').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params, false) },
    });
    $('#restrict_referer_delay,#restrict_referer_delay_extra').editable({
        mode: 'inline',
        params: (params) => { return editableParams(params, false) },
    });
}

$(function() {
    preInitXEditable();
});
