// DATATABLES
// -----------------------------------

import $ from 'jquery';

// Libraries for buttons
import * as JSZip from "jszip";

window.JSZip = JSZip;
import 'pdfmake/build/pdfmake' ;
import 'pdfmake/build/vfs_fonts' ;
// Datatables
import dt from 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-buttons-bs4';
import dtr from 'datatables.net-responsive-bs4'
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.css';
import dtk from 'datatables.net-keytable-bs4/js/keyTable.bootstrap4.js' ;

dt(window, $);
dtr(window, $);
dtk(window, $);

function initDatatables() {

  if (!$.fn.DataTable) return;

  // Zero configuration

  var emptyMsg = $('#datatable1').data('emptyMsg') || 'No data available in table';
  window.datatable1 = $('#datatable1').DataTable({
    paging: true, // Table pagination
    ordering: true, // Column ordering
    info: true, // Bottom left status text
    pageLength: 50,
    responsive: {
      details: {
        type: 'inline'
      }
    },
    language: {
      emptyTable: emptyMsg,
    },
    // Text translation options
    // Note the required keywords between underscores (e.g _MENU_)
    oLanguage: {
      sSearch: '<em class="fas fa-search"></em>',
      sLengthMenu: '_MENU_ records per page',
      info: 'Showing page _PAGE_ of _PAGES_',
      zeroRecords: 'Nothing found - sorry',
      infoEmpty: 'No records available',
      infoFiltered: '(filtered from _MAX_ total records)',
      oPaginate: {
        sNext: '<em class="fa fa-caret-right"></em>',
        sPrevious: '<em class="fa fa-caret-left"></em>'
      }
    }
  });

  $('.datatable1').DataTable({
    paging: true, // Table pagination
    ordering: true, // Column ordering
    info: true, // Bottom left status text
    pageLength: 50,
    responsive: {
      details: {
        type: 'inline'
      }
    },
    language: {
      emptyTable: emptyMsg,
    },
    // Text translation options
    // Note the required keywords between underscores (e.g _MENU_)
    oLanguage: {
      sSearch: '<em class="fas fa-search"></em>',
      sLengthMenu: '_MENU_ records per page',
      info: 'Showing page _PAGE_ of _PAGES_',
      zeroRecords: 'Nothing found - sorry',
      infoEmpty: 'No records available',
      infoFiltered: '(filtered from _MAX_ total records)',
      oPaginate: {
        sNext: '<em class="fa fa-caret-right"></em>',
        sPrevious: '<em class="fa fa-caret-left"></em>'
      }
    }
  });


  // Filter

  $('#datatable2').DataTable({
    'paging': true, // Table pagination
    'ordering': true, // Column ordering
    'info': true, // Bottom left status text
    pageLength: 50,
    responsive: true,
    // Text translation options
    // Note the required keywords between underscores (e.g _MENU_)
    oLanguage: {
      sSearch: 'Search all columns:',
      sLengthMenu: '_MENU_ records per page',
      info: 'Showing page _PAGE_ of _PAGES_',
      zeroRecords: 'Nothing found - sorry',
      infoEmpty: 'No records available',
      infoFiltered: '(filtered from _MAX_ total records)',
      oPaginate: {
        sNext: '<em class="fa fa-caret-right"></em>',
        sPrevious: '<em class="fa fa-caret-left"></em>'
      }
    },
    // Datatable Buttons setup
    dom: 'Bfrtip',
    buttons: [
      { extend: 'copy', className: 'btn-info' },
      { extend: 'csv', className: 'btn-info' },
      { extend: 'excel', className: 'btn-info', title: 'XLS-File' },
      { extend: 'pdf', className: 'btn-info', title: $('title').text() },
      { extend: 'print', className: 'btn-info' }
    ]
  });

  $('#datatable3').DataTable({
    'paging': true, // Table pagination
    'ordering': true, // Column ordering
    'info': true, // Bottom left status text
    responsive: true,
    // Text translation options
    // Note the required keywords between underscores (e.g _MENU_)
    oLanguage: {
      sSearch: 'Search all columns:',
      sLengthMenu: '_MENU_ records per page',
      info: 'Showing page _PAGE_ of _PAGES_',
      zeroRecords: 'Nothing found - sorry',
      infoEmpty: 'No records available',
      infoFiltered: '(filtered from _MAX_ total records)',
      oPaginate: {
        sNext: '<em class="fa fa-caret-right"></em>',
        sPrevious: '<em class="fa fa-caret-left"></em>'
      }
    },
    // Datatable key setup
    keys: true
  });

  // Zero configuration with default backend ordering, default frontend ordering disabled

  window.datatable4 = $('#datatable4').DataTable({
    paging: true, // Table pagination
    ordering: true, // Column ordering
    order: [], // Default ordering
    info: true, // Bottom left status text
    pageLength: 50,
    responsive: {
      details: {
        type: 'inline'
      }
    },
    // Text translation options
    // Note the required keywords between underscores (e.g _MENU_)
    oLanguage: {
      sSearch: '<em class="fas fa-search"></em>',
      sLengthMenu: '_MENU_ records per page',
      info: 'Showing page _PAGE_ of _PAGES_',
      zeroRecords: 'Nothing found - sorry',
      infoEmpty: 'No records available',
      infoFiltered: '(filtered from _MAX_ total records)',
      oPaginate: {
        sNext: '<em class="fa fa-caret-right"></em>',
        sPrevious: '<em class="fa fa-caret-left"></em>'
      }
    }
  });

  $('#datatable5').DataTable({
    paging: true, // Table pagination
    ordering: false, // Column ordering
    info: true, // Bottom left status text
    pageLength: 50,
    responsive: {
      details: {
        type: 'inline'
      }
    },
    language: {
      emptyTable: emptyMsg,
    },
    // Text translation options
    // Note the required keywords between underscores (e.g _MENU_)
    oLanguage: {
      sSearch: '<em class="fas fa-search"></em>',
      sLengthMenu: '_MENU_ records per page',
      info: 'Showing page _PAGE_ of _PAGES_',
      zeroRecords: 'Nothing found - sorry',
      infoEmpty: 'No records available',
      infoFiltered: '(filtered from _MAX_ total records)',
      oPaginate: {
        sNext: '<em class="fa fa-caret-right"></em>',
        sPrevious: '<em class="fa fa-caret-left"></em>'
      }
    }
  });
}

window.initDatatables = initDatatables

export default initDatatables;

