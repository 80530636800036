import $ from 'jquery';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';

$(document).ready(function() {
  $('#month-datepicker-filter').datepicker({
    format: "mm-yyyy",
    viewMode: "months",
    minViewMode: "months",
    orientation: "bottom",
    autoclose: true
  });
})

$('body').on('change', '#amount_adjustment_input', function () {
  var value = $(this).val(),
    estimatedAmount = parseInt($(this).data('estimatedAmount'));
  if (value) {
    $('#final_amount_input').val(estimatedAmount + parseInt(value));
  }
})
