import $ from 'jquery';
// import select2 from 'select2';
import 'select2/dist/css/select2.min.css';

function initNavbarSearch() {}

$( document ).ready(function() {
  $('.global-search').select2({
    allowClear: false,
    placeholder: "Search for Channel, Feed, Feed Tag, Account",
    multiple: true,
    width: '350',
    minimumInputLength: 3,
    cache: true,
    ajax: {
      delay: 1000,
      url: '/global_searches',
      data: function (params) {
        var query = { q: params.term}
        return query;
      },
      processResults: function (data) {
        return { results: data.results };
      }
    }
  }).on('select2:selecting', function (e) {
    e.preventDefault();
    window.location.href = e.params.args.data.url;
  });
});

export default initNavbarSearch;
