import $ from 'jquery';

// when a publisher is elected, show the available sub ids and select the first available one
$('.publisher-selection').change(function (event) {
  $.get("/channel_tags/publisher_available_tags", { publisher_id: event.target.value }).done(function (result) {

    var next_available = result.sub_ids[0]
    $('input[name="channel_tag[sub_id]"]').val(next_available)

    var available_sub_ids_list = result.sub_ids.join(', ')
    $('.sub-id-range').empty().append("available sub ids: " + available_sub_ids_list)

  });
})

function orderChannelTagTableByUpdatedAt() {
  datatable4.order([2, 'desc']);
  datatable4.draw();
}

$(document).ready(function() {
  orderChannelTagTableByUpdatedAt();
});

