import $ from 'jquery';

$(document).ready(function() {
  $('#profit-download-format').on('change', function (e) {
    if (e.target.value == 'Xlsx') {
      var url = $('.profit-download-link').attr('href').replace('.csv', '.xlsx');
      $('.profit-download-link').attr('href', url);
    } else {
      var url = $('.profit-download-link').attr('href').replace('.xlsx', '.csv');
      $('.profit-download-link').attr('href', url);
    }
  })
})
