import Rails from '@rails/ujs';

const ujsGet = (path, params, complete) => {
  Rails.ajax({
    type: "GET",
    url: path,
    data: new URLSearchParams(params).toString(),
    dataType: 'script',
    complete: complete
  })
}

window.ujsGet = ujsGet
window.Rails = Rails

export { ujsGet }
