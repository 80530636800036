import $ from 'jquery';

$(document).ready(function() {
  $("#show-hide-pwd").on('click', '.input-group-text', function(event) {
      event.preventDefault();
      if($('#show-hide-pwd input').attr("type") == "text"){
          $('#show-hide-pwd input').attr('type', 'password');
          $('#show-hide-pwd i').addClass( "fa-eye-slash" );
          $('#show-hide-pwd i').removeClass( "fa-eye" );
      }else if($('#show-hide-pwd input').attr("type") == "password"){
          $('#show-hide-pwd input').attr('type', 'text');
          $('#show-hide-pwd i').removeClass( "fa-eye-slash" );
          $('#show-hide-pwd i').addClass( "fa-eye" );
      }
  });
});
