import $ from 'jquery';
import { ujsGet } from "./ujs_helpers";

$(document).on('change', '.import-type', (event) => {
  const $this = $(event.target)
  const type = $this.val()
  const id = $this.data('id')
  ujsGet('/feed_data_services/load_import_type_fields', { type: type, id: id })
})

$(document).on('change', '.traffic-type', (event) => {
  const $this = $(event.target)
  const type = $this.val()
  const id = $this.data('id')
  ujsGet('/feed_data_services/load_traffic_type_fields', { type: type, id: id })
})

$(document).on('change', '.feed_name_fields', function () {
  if ($(this).val() === 'join') {
    $('.feed_tag_field_name').addClass('hidden')
  } else {
    $('.feed_tag_field_name').removeClass('hidden')
  }
})

