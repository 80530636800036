import $ from "jquery";
import { ujsGet } from "./ujs_helpers";
import Rails from '@rails/ujs';

import 'bootstrap/js/dist/popover';

import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';

function preInitXEditable() {
  if ($('.x-editable-card').length) {
    import('x-editable-bs4/dist/bootstrap4-editable/css/bootstrap-editable.css').then(() => {
      import('x-editable-bs4/dist/bootstrap4-editable/js/bootstrap-editable.min.js').then(() => {
        initXEditable();
      });
    });
  }
}

function editableParams(params) {
  params.authenticity_token = $('[name="csrf-token"]')[0].content;
  const ptParams = {};
  ptParams[params.name] = params.value;
  params.publisher_traffic_report = ptParams;
  return params;
}

function initXEditable() {

    if (!$.fn.editable) return

    // Font Awesome support
    $.fn.editableform.buttons =
        '<button type="submit" class="btn btn-primary btn-sm editable-submit">' +
        '<i class="fa fa-fw fa-check"></i>' +
        '</button>' +
        '<button type="button" class="btn btn-default btn-sm editable-cancel">' +
        '<i class="fa fa-fw fa-times"></i>' +
        '</button>';

    //defaults
    $.fn.editable.defaults.ajaxOptions = { type: "PUT" };


    $('.total_searches').editable({
        mode: 'inline',
        validate: function(value) {
            const numberValue = parseFloat(value);
            if (isNaN(numberValue)) return 'This field should be a number';
        },
        params: (params) => { return editableParams(params) },
        success: (response, newValue) => {
          initXEditable();
        }
    });

    $('.monetized_searches').editable({
        mode: 'inline',
        validate: function(value) {
            const numberValue = parseFloat(value);
            if (isNaN(numberValue)) return 'This field should be a number';
        },
        params: (params) => { return editableParams(params) },
        success: (response, newValue) => {
          initXEditable();
        }
    });
    $('.clicks').editable({
        mode: 'inline',
        validate: function(value) {
            const numberValue = parseFloat(value);
            if (isNaN(numberValue)) return 'This field should be a number';
        },
        params: (params) => { return editableParams(params) },
        success: function(response, newValue) {
          initXEditable();
        }
    });
    $('.amount').editable({
        mode: 'inline',
        validate: function(value) {
            const numberValue = parseFloat(value);
            if (isNaN(numberValue)) return 'This field should be a number';
        },
        params: (params) => { return editableParams(params) },
        success: (response, newValue) => {
          initXEditable();
        }
    });
}

$(document).ready(function() {
  window.onbeforeunload = () => { if ($("tr.loading-report")[0]) { return true } }

  preInitXEditable();
  toggleExport();

  $('.report-datepicker-filter').datepicker({
    format: "dd-mm-yyyy",
    orientation: "bottom",
    autoclose: true
  }).on('show', function(e) {
     $('.datepicker > div:first-of-type').css('display', 'initial');
  });

  // This is to override the default config for datatable to do the default sort
  window.publisherReportDatatable = $('#publisher-report-datatable').on(
      'order.dt', () => preInitXEditable()
    ).on(
      'page.dt',() => preInitXEditable()
    ).DataTable({
      'paging': true,
      'ordering': true,
      'info': true,
      order: [[8, 'desc']],
      pageLength: 50,
      responsive: true,
      columnDefs: [
        { "orderable": false, "targets": [0,1,2] }
      ],
      oLanguage: {
          sSearch: '<em class="fas fa-search"></em>',
          sLengthMenu: '_MENU_ records per page',
          info: 'Showing page _PAGE_ of _PAGES_',
          zeroRecords: 'Nothing found - sorry',
          infoEmpty: 'No records available',
          infoFiltered: '(filtered from _MAX_ total records)',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      }
  });

  $(document).on('click', '.ptr-history', function (e) {
    var self = $(this);
    var childId = `child-${self.data('report-id')}`

    if ($(`#${childId}`).length) {
      var historyConent = document.getElementById(`${childId}-history`);
      historyConent.style.transition = '0.5s';
      historyConent.style.maxHeight = '0';
      e.stopImmediatePropagation();
      e.preventDefault();
      setTimeout(() => {
        $(`#${childId}`).remove();
      }, 400)
    } else {
      self.closest('tr').after(`<tr id=${childId} class="ptr-histories-row"><td class="text-center bg-light" style="padding: 0 !important;" colspan="100%"><div id="${childId}-history" class="px-1" style='max-width: ${self.closest('.card').width() -30}px; transition: 0.2s; overflow-y: hidden;'><div class="ball-pulse py-3"><div></div><div></div><div></div></div></div></td></tr>`);
      setTimeout(() => {
        document.getElementById(`${childId}-history`).style.maxHeight = '60px';
      }, 50)
    }
  })
});

function toggleExport() {
  if ($('#publisher_id').val() != 0) {
    $('#publisher_traffic_reports_export').removeAttr('disabled');
  } else {
    $('#publisher_traffic_reports_export').attr('disabled', true);
  }
}

$(document).on('change', '#publisher_id', function() {
  toggleExport();
})

$(document).on('change', '.report-date', function () {
  console.log($(this).data('path'))
  window.location.href = `${$(this).data('path')}${$(this).val()}`
})


$(document).on('click', '#submit-generate-form', function () {
  if ($(".publisher-ids.generated:checked").length > 0) {
    swal({
      title: `Are you sure you want to override generated reports? This will completely override the existing reports`,
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, do it!',
          value: true,
          visible: true,
          className: "bg-success",
          closeModal: true
        }
      }
    }).then((willDelete) => {
      if (willDelete) {
        generateReports();
      } else {
        swal("Cancelled", "Ok, action canceled", "error");
      }
    });
  } else {
    generateReports()
  }
})

function generateReports() {
  $.each($(".publisher-ids:checked"), function(){
    document.getElementById('submit-generate-form').setAttribute('disabled', '');
    $("input[name='ids[]']").val($(this).val());
    Rails.fire(document.getElementById('generate-form'), "submit");
    $(`#publisher-row-${$(this).val()} .badge`).removeClass('badge-danger badge-success').addClass("generating-report").text('Generating...')
  });
}

$(document).on('click', '.publisher-submit-button', function () {
  $('.publisher-id-field').val($(this).data('publisherId'))
})

$(function() {
  // on submission, generate json from report rows, and submit
  $('.submit-report').click(function () {
    var data = $('.publisher-reports tbody tr').map(function () {
      return {
        date: $(this.cells[0]).text(),
        channel_tag_id: $(this.cells[1]).data('channelTagId'),
        report_type: $(this.cells[2]).data('reportType'),
        geo: $(this.cells[3]).text(),
        total_searches: $(this.cells[4]).text(),
        monetized_searches: $(this.cells[5]).text(),
        clicks: $(this.cells[6]).text(),
        payment: $(this.cells[7]).text(),
        coverage: $(this.cells[8]).text(),
        monetized_ctr: $(this.cells[9]).text(),
        publisher_cpc: $(this.cells[10]).text(),
        total_rpm: $(this.cells[11]).text(),
      };
    }).get();

    let report = JSON.stringify(data);

    saveReport(report)
  });

  $(document).on('click', '#load-traffic-reports', function (e) {
    e.preventDefault();
    $(this).closest('form').submit();
  })
});

function exportAll() {
  document.getElementById("publisher-report-card").classList.remove("hidden");
  $('#publisher_traffic_reports_export').removeAttr('disabled');
  const publishers = $('#export-all-publishers').data("publishers");
  const previousSelectedValue = $('#publisher_id').val();
  publishers.forEach((publisher, index) => {
    $('#publisher_id').val(publisher.id);
    let tableRow = document.createElement("tr");
    tableRow.classList.add('loading-report')
    tableRow.setAttribute('id', `publisher-${publisher.id}`)
    tableRow.innerHTML= `<td></td><td>${publisher.name}</td><td colspan="6"><strong>In Progress...</strong></td>`
    document.getElementById('tbody-publisher-report').prepend(tableRow)

    $('#publisher_traffic_reports_export').trigger("click");
    if ((index + 1) == publishers.length) {
      $('#publisher_id').val(previousSelectedValue);
      $('#publisher_id').trigger("change");
    }
  })
}

$(function() {
  $(document).on('click', '#export-all-publishers', function (e) {
    e.preventDefault();
    swal({
      title: `Are you sure you want to export all publishers?`,
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, do it!',
          value: true,
          visible: true,
          className: "bg-success",
          closeModal: true
        }
      }
    }).then((willDelete) => {
      if (willDelete) {
        exportAll();
      } else {
        swal("Cancelled", "Ok, action canceled", "error");
      }
    });
  })
})

function saveReport(report) {
  let token = $('meta[name="csrf-token"]').attr('content');
  let searchParams = new URLSearchParams(window.location.search);
  let publisher_id = searchParams.get('publisher_id');

  jQuery.ajax({
    type: "POST",
    url: '/publisher_traffic_reports',
    headers: { 'X-CSRF-Token': token },
    data: { report: report, publisher_id: publisher_id },
    beforeSend: function () {
      $('.submit-report').addClass('disabled')
      $('.loader').show()
    },
    complete: function (result) {
      $('.loader').hide()
      if (result.responseJSON.success) {
        let success_div = "<div class='alert alert-success mt-4'>All right! the publisher report is created! Reloading</div>"
        $('.actions').append(success_div)
        setTimeout(function () {
          window.location.href = '/publisher_traffic_reports/create_report'
        }, 1000);
      } else {
        let failure_div = "<div class='alert alert-warning mt-4 send_result' id='send_result'>Something went wrong: " + result.responseJSON.message + "</div>"
        $('.actions').append(failure_div)
      }
    }
  });
}

function validateMinMaxFields(min, max) {
  let minVal = parseFloat(min)
  let maxVal = parseFloat(max)
  return minVal <= maxVal && minVal >=0 && minVal <=100 && maxVal >=0 && maxVal <=100
}

$(function() {
  $(document).on('change', '.min-field', function(e) {
    let $minField = $(e.target);
    let $maxField = $minField.closest('form').find('.max-field');
    let $submitBtn = $minField.closest('form').find('input[type="submit"]');

    if (validateMinMaxFields($minField.val(), $maxField.val())) {
      $submitBtn.prop('disabled', false);
    } else {
      $submitBtn.prop('disabled', true);
    }
  })

  $(document).on('change', '.max-field', function(e) {
    let $maxField = $(e.target);
    let $minField = $maxField.closest('form').find('.min-field');
    let $submitBtn = $maxField.closest('form').find('input[type="submit"]');

    if (validateMinMaxFields($minField.val(), $maxField.val())) {
      $submitBtn.prop('disabled', false);
    } else {
      $submitBtn.prop('disabled', true);
    }
  })
});
