import $ from "jquery";
import { ujsGet } from "../ujs_helpers";

$('#history-content').on('show.bs.collapse	', function() {
  $('#history-button').html('Hide history')
})
$('#history-content').on('hide.bs.collapse	', function() {
  $('#history-button').html('Show history')
})

$('body').on('change', '#routing_details_publisher', function (e) {
  if (!$(this).val()) return

  ujsGet(
    "/channel_tags/by_publisher",
    { id: $(this).val(), dropdown_id: 'routing_details_channel_tag_id' }
  )

  ujsGet(
    "/channel_tags/wizard/publisher_related_fields",
    { publisher_id: $(this).val(), form_name: "routing_details" }
  )
})

$('body').on('change', '.restrict-referer-item', function (e) {
  const value = this.value
  if (value === 'any') {
    $(this).closest('.restrict-referer').find('.restrict-referer-item:not(#restrict-referer-item-any)').prop("checked", false)
  } else {
    $(this).closest('.restrict-referer').find('#restrict-referer-item-any').prop("checked", false)
  }
})

$('body').ready(function () {
  checkRemoveButtonLimitation()
  checkCreateNewFeedTag()
})

$('body').on('click', '.submit-button', function (e) {
  $(this).addClass('hidden')
  $('#loader').removeClass('hidden')
})

$('body').on('change', '.feed_reloader', function (e) {
  if (!$(this).val()) return

  ujsGet(
    "/feeds/by_account",
    { id: $(this).val(), dropdown_id: $(this).data('targetReload') }
  )

  if ($(this).data('publisherRelatedFields')) {
    ujsGet(
      "/channel_tags/wizard/publisher_related_fields",
      { publisher_id: $(this).val(), form_name: $(this).data('formName'), key: $(this).data('key') }
    )
  }
  checkCreateNewFeedTag($(this).data('key'))
})

$('body').on('change', '.feed_tag_reloader', function (e) {
  if ($(this).val() != "") {
    ujsGet(
      "/feeds/with_tags",
      {
        id: $(this).val(),
        dropdown_id: $(this).data('targetReload'),
        channel_tag_id: $(this).data('channelTagId') || '',
        base_url_field: $(this).data('baseUrlField'),
        without_channel: true
      }
    )

    ujsGet(
      "/channel_tags/wizard/publisher_related_fields",
      {
        publisher_id: $('.feed_reloader').val(),
        feed_id: $(this).val(),
        form_name: $(this).data('formName'),
        key: $(this).data('key')
      }
    )
  }
  checkCreateNewFeedTag($(this).data('key'))
})

$('body').on('change', '#routing_details_channel_tag_id', function (e) {
  if (!$(this).val()) return

  ujsGet(
    `/channel_tags/${$(this).val()}/wizard/channel_tags_details_fields`
  )
})

$('body').on('click', '.add-new-target-rule', function (e) {
  $(this).attr('disabled', 'disabled')
  ujsGet(`/channel_tags/${$(this).data('channelTagId')}/wizard/add_target_rule`, {}, () => {
    if (document.querySelectorAll('.feed_record_fields').length < $(this).data('maxCount')) {
      $(this).removeAttr('disabled')
    }
    checkRemoveButtonLimitation()
    checkCreateNewFeedTag($('.feed_record_fields').last().data('key'))
  })
})

$('body').on('click', '.remove-new-target-rule', function (e) {
  $(this).closest('.feed_record_fields').remove();
  checkRemoveButtonLimitation()
})


$('body').on('change', '.hidden-toggle', function (e) {
  if ($(this).is(':checked')) {
    $($(this).data('hiddenTarget')).removeClass('hidden');
    $($(this).data('disableTarget')).attr('disabled', 'disabled');
    $($(this).data('enableTarget')).removeAttr('disabled');
    $($(this).data('unableTarget')).removeAttr('disabled');
  } else {
    $($(this).data('hiddenTarget')).addClass('hidden');
    $($(this).data('disableTarget')).removeAttr('disabled');
    $($(this).data('enableTarget')).attr('disabled', 'disabled');
    $($(this).data('unableTarget')).attr('disabled', 'disabled');
  }
})

const checkCreateNewFeedTag = (formName = "main") => {
  const account = $(`#form_${formName}_account_id`).val();
  const feed = $(`#form_${formName}_feed_id`).val();

  if (account && feed) {
    $(`#form_${formName}_create_new_feed_tag`).removeAttr('disabled');
  } else {
    $(`#form_${formName}_create_new_feed_tag`).attr('disabled', 'disabled');
  }
}

const checkRemoveButtonLimitation = () => {
  const buttons = document.querySelectorAll('.remove-new-target-rule')
  if (buttons.length === 1) {
    buttons.forEach(function (elm) {
      elm.setAttribute('disabled', 'disabled')
    })
  } else {
    buttons.forEach(function (elm) {
      elm.removeAttribute('disabled')
    })
  }
}
