import $ from 'jquery';
import swal from "sweetalert";

checkSourceType();

function checkSourceType() {
  const sourceType = $('.source_type').val();

  const channelTag = $('.traffic_limit_channel_tag');
  const feed = $('.traffic_limit_feed');
  const ip = $('.traffic_limit_ip');
  const channelTagSelect = channelTag.find('select');
  const feedSelect = feed.find('select');
  const ipInput = ip.find('input');

  channelTag.addClass('hidden');
  feed.addClass('hidden');
  ip.addClass('hidden');
  channelTagSelect.prop("disabled", true);
  feedSelect.prop("disabled", true);
  ipInput.prop("disabled", true);

  if (sourceType === 'channel') {
    channelTag.removeClass('hidden');
    channelTagSelect.prop("disabled", false);
  } else if (sourceType == 'feed') {
    feed.removeClass('hidden');
    feedSelect.prop("disabled", false);
  } else if (sourceType == 'ip') {
    ip.removeClass('hidden');
    ipInput.prop("disabled", false);
  }
}

$(function() {
  $('#traffic_limit_source_type').on('change', function (e) {
    checkSourceType();
  })

  $('#publisher').on("select2:select", (e) => {
    const publisher_id = e.params.data.id;
    const channelTagSelect = $("#traffic_limit_channel_tag_id");
    const options = channelTagSelect.data('select2').options.options;
    const items = [{ id: '', text: "Loading"}];

    channelTagSelect.html('');
    channelTagSelect.append(`<option value="">Loading</option>`)
    options.data = items;
    channelTagSelect.select2(options);

    const parameters = {
      without_traffic_limit: true,
      account_id: publisher_id,
      sort_by: 'tag asc'
    }

    $.get(
      '/channel_tags',
      parameters,
      (data) => {
        var placeholder = "Select Channel Tag";
        items.pop();
        if (data.length == 0) {
          placeholder = "Publisher has no channel tag";
          options.disabled = true
        } else {
          options.disabled = false
        }
        items.push({ id: '', text: placeholder});
        channelTagSelect.html('');
        channelTagSelect.append(`<option value="">${placeholder}</option>`)

        data.forEach((channelTag) => {
          items.push({id: channelTag.id, text: channelTag.tag});
          channelTagSelect.append(`<option value=${channelTag.id}>${channelTag.tag}</option>`)
        });

        options.data = items;
        channelTagSelect.select2(options);
      },
      'json'
    );
  });
});
