import { ujsGet } from "./ujs_helpers";

$('.type').change(function (event) {
  filterRecords();
});

$('.status').change(function (event) {
  filterRecords();
});

function filterRecords() {
  ujsGet(`/accounts`, { type: $('.type').val(), status: $('.status').val() });
}

$('.account-type').change(function (event) {
  const $this = $(event.target)
  const type = $this.val()
  const accountId = $this.parent().data('id')
  ujsGet('/accounts/load_account_type_fields', { account_id: accountId, type: type })
});
